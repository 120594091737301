export enum ServerErrorCode {
	SCHEDULING_INTERNAL_ERROR = 'schedulingInternalError',
	SCHEDULING_NOT_FOUND = 'schedulingNotFound',
	SCHEDULING_APPOINTMENT_TYPE_UNAVAILABLE = 'schedulingAppointmentTypeUnavailable',
	SCHEDULING_APPOINTMENT_EXISTS = 'schedulingAppointmentExists',
	SCHEDULING_WORK_SHIFT_DURATION_SHORT = 'schedulingWorkShiftDurationShort',
	SCHEDULING_WORK_SHIFT_DURATION_LONG = 'schedulingWorkShiftDurationLong',
	SCHEDULING_WORK_SHIFT_BREAK_INVALID = 'schedulingWorkShiftBreakInvalid',
	SCHEDULING_WORK_SHIFT_ASSIGNED_IN_PRESENT_OR_FUTURE = 'schedulingWorkShiftAssignedInPresentOrFuture',
	SCHEDULING_SHORTEN_WORK_SHIFT_APPOINTMENT_STARTED = 'schedulingShortenWorkShiftAppointmentStarted',
	SCHEDULING_APPOINTMENT_EMPLOYEE_REQUIREMENTS_UNMET = 'schedulingAppointmentEmployeeRequirementsUnmet',
	SCHEDULING_RESERVATION_IN_PAST = 'schedulingReservationInPast',
	SCHEDULING_RESERVATION_OUTSIDE_WORK_SHIFT = 'schedulingReservationOutsideWorkShift',
	SCHEDULING_OVERLAPPING_RESERVATION = 'schedulingOverlappingReservation',
	SCHEDULING_ARGUMENTS_INVALID = 'schedulingArgumentsInvalid',
	SCHEDULING_WORK_SHIFT_INVALID = 'schedulingWorkShiftInvalid',
	SCHEDULING_INACTIVE_WORK_SHIFT_USED = 'schedulingWorkShiftInactive',
	SCHEDULING_ROOM_UNAVAILABLE = 'schedulingRoomUnavailable',
	SCHEDULING_ROOM_EVENT_FINISHED = 'schedulingRoomEventFinished',
	SCHEDULING_CLINIC_NON_WORKING_DAY = 'schedulingClinicNonWorkingDay',
	SCHEDULING_WORK_SHIFT_NOT_ASSIGNED = 'schedulingWorkShiftNotAssigned',
	SCHEDULING_INTERVAL_INVALID = 'schedulingIntervalInvalid',
	SCHEDULING_INTERVAL_CEIL_FIVE_MULTIPLES = 'schedulingIntervalCeilFiveMultiples',
	SCHEDULING_RESERVE_BLOCK_TYPE_UNSUPPORTED = 'schedulingReserveBlockTypeUnsupported',
	SCHEDULING_NON_WORKING_DAY_FOR_CLINIC_ALREADY_EXISTS = 'schedulingNonWorkingDayForClinicAlreadyExists',
	SCHEDULING_NON_WORKING_DAY_WITH_APPOINTMENTS = 'schedulingNonWorkingDayWithAppointments',
	MEDICAL_INTERNAL_ERROR = 'medicalInternalError',
	MEDICAL_APPOINTMENT_IN_FUTURE = 'medicalAppointmentInFuture',
	MEDICAL_APPOINTMENT_IN_PAST = 'medicalAppointmentInPast',
	MEDICAL_APPOINTMENT_FINISHED = 'medicalAppointmentFinished',
	MEDICAL_APPOINTMENT_CANCELED = 'medicalAppointmentCanceled',
	MEDICAL_ACTION_UNAVAILABLE_FOR_ONLINE_APPOINTMENT = 'medicalActionUnavailableForOnlineAppointment',
	MEDICAL_APPOINTMENT_NOT_FOUND = 'medicalAppointmentNotFound',
	MEDICAL_APPOINTMENT_NOT_STARTED = 'medicalAppointmentNotStarted',
	MEDICAL_APPOINTMENT_STALE = 'medicalAppointmentStale',
	MEDICAL_APPOINTMENT_MISSING_EXAMINATION = 'medicalAppointmentMissingExamination',
	MEDICAL_BLOOD_EXAMINATION_NOT_FOUND = 'medicalBloodExaminationNotFound',
	MEDICAL_PATIENT_NOT_FOUND = 'medicalPatientNotFound',
	MEDICAL_INTERVIEW_SHEET_NOT_FOUND = 'medicalInterviewSheetNotFound',
	MEDICAL_PATIENT_CONDITIONS_EXIST = 'medicalPatientConditionsExist',
	MEDICAL_PATIENT_CONDITIONS_NOT_EXIST = 'medicalPatientConditionsNotExist',
	MEDICAL_ADDRESS_NOT_FOUND = 'medicalAddressNotFound',
	MEDICAL_APPOINTMENT_EXAMINATION_FINISHED = 'medicalAppointmentExaminationFinished',
	USER_PASSWORD_MISMATCH = 'userPasswordMismatch',
	USER_NOT_FOUND = 'userNotFound',
	USER_TOKEN_INVALID = 'userTokenInvalid',
	FORBIDDEN = 'forbidden',
	LOGIN_INVALID = 'loginInvalid',
	LOGIN_FAILED = 'loginFailed',
	TOKEN_EXPIRED = 'tokenExpired',
	REFRESH_TOKEN_INVALID = 'refreshTokenInvalid',
	SMS_SERVICE_UNAVAILABLE = 'smsServiceUnavailable',
	PHONE_INVALID = 'phoneInvalid',
	ORDER_MANAGEMENT_MEDICINE_NOT_AVAILABLE_IN_STOCK = 'orderManagementMedicineNotAvailableInStock',
	MEDICAL_APPOINTMENT_VERSION_DIFFERS = 'MEDICAL_APPOINTMENT_VERSION_DIFFERS',
	MEDICAL_EMAIL_PATIENT_ALREADY_EXISTS = 'medicalEmailPatientAlreadyExists',
	NOTIFICATION_PATIENT_MISSING_DEVICE_TYPE = 'notificationPatientMissingDeviceType',
	NOTIFICATION_PATIENT_MISSING_DEVICE_TOKEN = 'notificationPatientMissingDeviceToken',
	NOTIFICATION_PATIENT_EXPIRED_DEVICE_TOKEN = 'notificationPatientExpiredDeviceToken',
	NOTIFICATION_IN_THE_PAST = 'notificationInThePast',
	ORDER_MANAGEMENT_COUPON_RESTRICTED = 'orderManagementCouponRestricted',
	ORDER_MANAGEMENT_MEDICINE_USED_IN_ORDERS = 'orderManagementMedicineUsedInOrders',
	ORDER_MANAGEMENT_MEDICINE_USED_IN_MEDICINE_STOCK = 'orderManagementMedicineUsedInMedicineStock',
	MEDICAL_RECORD_PATIENT_ALREADY_MARKED_FOR_DELETE = 'medicalRecordPatientAlreadyMarkedForDelete',
	GENERIC_ERROR = 'genericError'
}
